import axios from "axios";
import { objToFormData } from "~/utils/utils";

const axiosInstance = function (base) {
  const hostname = window.location.hostname;
  let baseURL = base || "/api";
  /* if (["localhost", "dev.jwj.kr"].includes(hostname)) {
    baseURL = base || "/api";
  } else {
    baseURL = `https://jwj.kr${base || "/api"}`;
  } */

  return axios.create({
    baseURL,
    timeout: 50000,
    withCredentials: true,
    headers: {
      Accept: "*/*",
      "Content-Type": "multipart/formed-data",
    },
  });
};

/**
 * @title [075] 내 나눔방 목록 API
 */
export const getMyRoomListAPI = async () => {
  return axiosInstance("/ajax").get(`/room/selectMyRoomMainList.do`);
};

/**
 * @title [076] 오픈 나눔방 목록
 */
export const getOpenRoomListAPI = async () => {
  return axiosInstance("/ajax").get(`/room/selectOpenRoomList.do`);
};

/**
 * @title [077] 나눔방 상단 고정
 * @roomCd : 나눔방코드
 * @delYn : 상단고정여부(고정:N,해제:Y)
 */
export const setFixRoomAPI = async (payload) => {
  return axiosInstance("/ajax").post(`/room/insertFixedRoom.do`, objToFormData({ ...payload }));
};

/**
 * @title [078] 나눔방 일기 목록
 * @roomCd : 나눔방 코드
 * @startNum : 페이지 시작값(기본값 0)
 */

export const getRoomDiaryListAPI = async (payload) => {
  return axiosInstance(`/ajax`).post(`/room/selectRoomOpenDiaryList.do`, objToFormData({ ...payload }));
};

/**
 * @title [079] 화상 개설 여부
 * @roomCd : 나눔방 코드
 */
export const getRemoteMeetingAPI = async (payload) => {
  //https://jwj.kr/ajax/room/selectRemoteMeeting.do
  return axiosInstance(`/ajax`).post(`/room/selectRemoteMeeting.do`, objToFormData({ ...payload }));
};

/**
 * @params {string} roomCd
 * @params {string} requestId
 * @params {string} state
 */
export const initRemoteMeetingAPI = async (payload) => {
  return axiosInstance(`/ajax`).get(`/room/initRemoteMeeting.do`, { params: { ...payload } });
};

/**
 * @params {string} requestId : 리모트미팅 requestId
 * @params {string} joinRequestId : 참여자 requestId
 */
export const joinRemoteMeetingAPI = async (payload) => {
  return axiosInstance(`/ajax`).get(`/room/joinRemoteMeeting.do`, { params: { ...payload } });
};

/**
 * @title [080] 나눔방 상세 정보
 * @roomCd : 나눔방 코드
 */
//https://jwj.kr/ajax/room/roomMainInfo.do
export const getRoomInfoAPI = async (payload) => {
  return axiosInstance(`/ajax`).post(`/room/roomMainInfo.do`, objToFormData({ ...payload }));
};

/**
 * @title [081] 나눔방 정보 수정
 * @roomCd : 나눔방 코드(필수, 나머지는 수정할 값만)
 * => roomName, roomDesc, joinLimitYn(가입제한여부 Y/N), menAllow(YN), womenAllow(YN), roomClosedYn(나눔방 폐쇄여부 Y/N), roomClosedRsn(나눔방 폐쇄사유), roomClosedDate(나눔방 폐쇄일자)
 * => chiefClosedYn(나눔방장 폐쇄여부), grpCd(그룹코드), lowerGrpCd(그룹 하위코드)
 */
export const updateRoomInfoAPI = async (payload) => {
  return axiosInstance(`/ajax`).post(`/room/updateRoomProc.do`, objToFormData({ ...payload }));
};

/**
 * [083-1] 나눔방 공지(알림) 수정
 *  @roomCd
 *  @roomNotiCd
 *  @notiCnts
 */
export const updateRoomNotiAPI = async (payload) => {
  return axiosInstance(`/ajax`).post(`/diary/updateRoomNotiProc.do`, objToFormData({ ...payload }));
};

/**
 * [083-2] 나눔방 공지(알림) 삭제
 * @roomCd
 * @roomNotiCd
 * @delYn : 삭제여부(Y)
 */
export const delRoomNotiAPI = async (payload) => {
  return axiosInstance(`/ajax`).post(`/diary/updateRoomNotiProc.do`, objToFormData({ ...payload }));
};
/**
 * [082] 나눔방 공지 저장 API
 * @roomCd
 * @memCd
 * @joinStDate
 * @notiCnts
 * @notiRegLDate
 */
export const saveRoomNotiAPI = async (payload) => {
  return axiosInstance(`/ajax`).post(`/room/insertRoomNotiProc.do`, objToFormData({ ...payload }));
};

/**
 * @title [084] 나눔방 가입 요청/초대
 */
export const joinRoomAskAPI = async (payload) => {
  return axiosInstance(`/ajax`).post(`/room/insertRoomJoinAsk.do`, objToFormData({ ...payload }));
};

/**
 * [085] 나눔방 초대 승인/ 초대 거부
 * --> 나눔방 api에서 리턴해주는 json string 값을 참고한다.
 */
export const respondInvtAPI = async (payload) => {
  return axiosInstance(`/ajax`).post(`/util/insertAlarmProc.do`, objToFormData({ ...payload }));
};

/**
 * @title [086] 나눔방 검색
 * @roomName : 나눔방 이름
 * @siteLangCd : 언어
 */
export const searchRoomAPI = async (payload) => {
  return axiosInstance(`/ajax`).post(`/room/selectRoomToJoinList.do`, objToFormData({ ...payload }));
};

/**
 * @title [087-1] 나눔방 탈퇴
 * @roomCd, joinStDate, memCd, joinEdDate, selfQuitYn, quitRsn
 */
export const withDrawRoomAPI = async (payload) => {
  return axiosInstance(`/ajax`).post(`/room/updateRoomMemProc.do`, objToFormData({ ...payload }));
};

/**
 * @title [087-2] 나눔방 강퇴(승인거절)
 * @roomCd, joinStDate, memCd(삭제/승인 거절할 회원코드), joinAskDate, cnfmFnshDate, refusalRsn, quitRsn
 * @gubun(가입회원:mem, 초대한회원:invt, 가입요청:ask)
 * @joinEdDate, sendMemCd(로그인한 사용자의 memCd(방장))
 * schTxt : delMemCd + $ + joinStDate + $ gubun
 *
 */
export const rejectRoomAPI = async (payload) => {
  return axiosInstance(`/ajax`).post(`/room/updateRoomMemProc.do`, objToFormData({ ...payload }));
};

/**
 * @title [087-3] 나눔방 알림 설정 변경
 * @roomCd, joinStDate, memCd, alarmRcvYn
 */
export const changeRoomAlarmAPI = async (payload) => {
  return axiosInstance(`/ajax`).post(`/room/updateRoomMemProc.do`, objToFormData({ ...payload }));
};

/**
 * @title [087-4] 나눔방 모두 읽음 표시
 * @roomCd, joinStDate, memCd, readMarkDate(읽음표시 기준일자 : 현재일시)
 */
export const readAllRoomAPI = async (payload) => {
  return axiosInstance(`/ajax`).post(`/room/updateRoomMemProc.do`, objToFormData({ ...payload }));
};

/**
 * [088] 나눔방 삭제
 * @roomCd, roomClsedYn, roomClosedDate, chiefClosedYn, roomClosedRsn, quitRsn, joinEdDate, selfQuitYn
 */
export const delRoomAPI = async (payload) => {
  return axiosInstance(`/ajax`).post(`/room/deleteRoomPorc.do`, objToFormData({ ...payload }));
};

/**
 * [089] 나눔방 방장 지정 API
 * @roomCd, memCd(로그인한 사용자), joinStDate(가입시작일시), chiefMemCd(방장으로 지정할 회원코드), chiefJoinStDate(변경할 나눔방장 가입시작일시), siteLangCd
 */
export const changeRoomChiefAPI = async (payload) => {
  return axiosInstance(`/ajax`).post(`/room/changeChiefProc.do`, objToFormData({ ...payload }));
};

/**
 * @title [090] 나눔방 멤버 검색
 * @memName : 회원이름
 * @memContact : 연락처
 * @memEmail : 이메일 // 연락처 || 이메일 둘 중 하나는 필수
 * @param : 연락처 || 이메일 보낼 때 키 이름 (memContact || memEmail)
 */
export const selectInviteMemAPI = async (payload) => {
  return axiosInstance(`/ajax`).post(`/usr/selectInviteMemInfo.do`, objToFormData({ ...payload }));
};

/**
 * @title [091] 나눔방 만들기
 * @memCd, siteLangCd, roomName, roomDesc, grpCd, roomBldLDate, chiefYn, bldrYn, cnfmYn, cnfmFnshDate, invtYn, roomClosedYn, inviteMem[]
 * @joinLimitYn, menAllow, womenAllow
 */
export const makeRoomAPI = async (payload) => {
  return axiosInstance("/ajax").post("/room/makeRoomProc.do", objToFormData({ ...payload }));
};

/**
 * @title [092] 교회/단체 검색
 * @grpName : 검색어(교회/단체 이름)
 * @memCd : 회원코드
 * @siteLangCd : 언어코드
 */
export const searchChurchAPI = async (payload) => {
  return axiosInstance(`/ajax`).post(`/grp/selectGroupSearchList.do`, objToFormData({ ...payload }));
};

/**
 * 우리교회/단체 등록 때 쓰이는 국가 검색
 */
export const getNationListAPI = async () => {
  return axiosInstance("/ajax").post("/code/nationCodeList.do");
};

/**
 * 교단 목록 조회
 */
export const getDenominationListAPI = async () => {
  return axiosInstance("/common").post("/code/commonCodeList.do", objToFormData({ upperCommonCd: `COMMONCD002000000000` }));
};

/**
 * 지역 조회
 */
export const getRegionListAPI = async () => {
  return axiosInstance("/common").post("/code/commonCodeList.do", objToFormData({ upperCommonCd: `COMMONCD013000000000` }));
};

/**
 * [093] 교회/단체에 멤버로 가입
 * @grpCd : 그룹(교회/단체) 코드
 * @memCd : 로그인한 사용자 코드
 * @siteLangCd : 언어코드
 * @selfAgreeYn : 본인동의 여부(Y)
 * @cnfmFnshDate : 승인완료일시(YYYYMMDDhhss)
 */
export const insertGrpMemAPI = async (payload) => {
  return axiosInstance(`/ajax`).post(`/grp/insertGroupMemProc.do`, objToFormData({ ...payload }));
};

/**
 * @title [094] 교회/단체 등록
 * @grpName : 그룹(교회/단체) 이름
 * @belongCity : 도시이름
 * @bldAskLDate : 개설신청일시
 * @grpTypeCd : 그룹유형코드
 * @siteLangCd : 언어코드
 * @nationCd
 * @ldrName: 대표자 이름
 * @memCd : 회원 코드
 * @grpContact : 대표자 연락처
 * @bldrYn : 개설여부, Y
 * @grpMngrYn : 그룹관리여부, Y
 */
export const registGroupAPI = async (payload) => {
  return axiosInstance("/ajax").post("/grp/makeGroupProc.do", objToFormData({ ...payload }));
};

/**
 * @param {string} roomCd
 * @param {string} grpCd
 */
export const getRoomGrpLevelAPI = async (payload) => {
  return axiosInstance(`/ajax`).get(`/room/selectRoomGrpLevel.do`, { params: { ...payload } });
};

/**
 *
 * @param {string} roomCd : 나눔방 코드
 * @param {string} diarySortDate : 일기 정렬 기준일자
 * @param {string} fromLoc : 요청위치(room:나눔방, diary:일기장)
 */

export const getPrevDiaryAPI = async (payload) => {
  return axiosInstance(`/ajax`).get(`/util/selectPrevDairy.do`, { params: { ...payload } });
};
